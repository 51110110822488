import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink } from '@apollo/client';
import {
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
// import { getToken } from './AuthProvider';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Auth, getAuth } from 'firebase/auth';
import _ from 'lodash';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { invariant } from 'ts-invariant';
import captureException from 'utils/captureException';
import Config from '../config';

const errorHandler = (error: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = error;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations }) => {
      const graphQLErrorMsg = `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${extensions?.path}`
      invariant.warn(graphQLErrorMsg);
      captureException(new Error(graphQLErrorMsg), {code: extensions?.code, path: extensions?.path, message, locations});
    });
  }

  if (_.isObject(networkError)) {
    invariant.warn(false, `[Network error]: Operation: ${operation.operationName}, Error: ${networkError}`);

    captureException(networkError);
  }
};

let wsHasuraLink;

const createLink = (setLastUpdateFn, firebaseAuth: Auth) => {

  const authMiddleware = setContext(() => {
    const headers: Record<string, unknown> = {};
    // console.log('Enter authMiddleware Promise');
    headers["JWT_AUD"] = "https://identitytoolkit.googleapis.com/google.identity.identitytoolkit.v1.IdentityToolkit"
    if (firebaseAuth.currentUser) {
      return firebaseAuth.currentUser.getIdToken().then(token => {
        if (token) headers['Authorization'] = `Bearer ${token}`;
        return { headers };
      });
    }
    return { headers };
  });

  const lastUpdateMiddleware = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      // console.log('Enter lastUpdateMiddleware');
      const { data, errors } = response;
      if (data) {
        const list = Object.keys(data);
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          const register = data[element];
          if (hasAnyMutation(operation)) {
            setLastUpdateFn(register?.updatedAt ?? moment().format("YYYY-MM-DDTHH:mm:ss.SSS"));
            // window['lastUpdate'] = register.updatedAt ?? moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
          }
        }
        const context = operation.getContext();
        const { response: { headers } } = context;

        if (headers && headers.get("Authorization")) {
          const token = headers.get("Authorization").split(' ')[1];

          if (token) {
            // login({ token: token })
            if (response['data']) {
              response['data']['token'] = token;
            }
            // setToken(token);
          }
        }
      } else if (errors) {
        console.log(errors);
      }
      return response;
    });
  });

  const railsLoginLink = createHttpLink({
    uri: Config.GRAPHQL_ENDPOINT,
    credentials: 'same-origin',
  });

  const httpHasuraLink = createHttpLink({
    uri: Config.GRAPHQL_HASURA_ENDPOINT,
    credentials: 'same-origin',
  });

  wsHasuraLink = new WebSocketLink({
    uri: Config.WEBSOCKET_HASURA_ENDPOINT,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => new Promise((resolve, reject) => {
        // console.log('Enter ConnectionParams Promise');
        const headers = {
          "JWT_AUD": "https://identitytoolkit.googleapis.com/google.identity.identitytoolkit.v1.IdentityToolkit"
        }
        if (firebaseAuth.currentUser) {
          firebaseAuth.currentUser.getIdToken().then(token => {
            if (token) headers['Authorization'] = `Bearer ${token}`;
            resolve({ headers });
            return null;
          }).catch(err => {
            captureException(err);
            reject(err);
          });
        } else {
          resolve({ headers });
        }
      })
    }
  });

  const hasSubscriptionOperation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation, name }) => (kind === 'OperationDefinition' && operation === 'subscription') || (kind === 'OperationDefinition' && operation === 'mutation' && name && name.value === 'UpdateUploadState')
    )
  }

  const useRailsGraphql = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation, name }) => kind === 'OperationDefinition' && (
        (operation === 'query' && name && ['FindWithInvitationToken', 'UnlockUser'].includes(name.value)) ||
        (operation === 'mutation' && name && ['ResendInvitation', 'Login', 'CreateNewMember', 'AcceptInvitation', 'AcceptInvitationWithProvider', 'SendResetPassword', 'ResetPassword', 'UserCheck'].includes(name.value))
      )
    )
  }

  const hasAnyMutation = ({ query: { definitions } }) => {
    return definitions.some(
      ({ kind, operation, name }) => kind === 'OperationDefinition' && operation === 'mutation'
    )
  }

  const hasuraLink = ApolloLink.split(
    // if has subscription operation
    hasSubscriptionOperation,
    // use wsHasuraLink
    wsHasuraLink,
    // or use authMiddleware and lastUpdateMiddleware with hasura link
    ApolloLink.from([authMiddleware, lastUpdateMiddleware, httpHasuraLink])
  );

  // This is the final GraphQL Link
  const appLink = ApolloLink.split(
    // if has login mutation
    useRailsGraphql,
    // use authMiddleware and lastUpdateMiddleware with rails login link
    ApolloLink.from([authMiddleware, lastUpdateMiddleware, railsLoginLink]),
    // or use hasura link
    hasuraLink
  );

  return ApolloLink.from([onError(errorHandler), appLink]);
};

const createCache = () => new InMemoryCache({
  typePolicies: {
    processings_projects_aggregate: {
      keyFields: (object, context) => {
        // console.log({object});
        const projectId = object?.nodes?.at(0)?.projectId;
        return `processings_projects_aggregate:{"projectId":"${projectId}"`;
      }
    },
    processings_projects: {
      keyFields: ["projectId", "processingId"],
    },
    processingsProjects: {
      keyFields: ["projectId", "processingId"],
    },
    // projects: {
    //   fields: {
    //     processingsProjects: {
    //       merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
    //         console.log({existing, incoming});
    //         return incoming.map(i => mergeObjects({}, i));
    //       }
    //     }
    //   }
    // },
    projectImageFiles: {
      keyFields: ["id"]
    },
    project_image_files: {
      keyFields: ["id"]
    },
    annotationFolderPermissions: {
      keyFields: ["id"]
    },
    annotation_folder_permissions: {
      keyFields: ["id"]
    },
    annotationPermissions: {
      keyFields: ["id"]
    },
    annotation_permissions: {
      keyFields: ["id"]
    },
    annotations: {
      keyFields: ["id"]
    },
    permissions: {
      keyFields: ["id"]
    },
    spatial_ref_sys: {
      keyFields: ["srid"],
    },
    epsg_epsg_coordinatereferencesystem: {
      keyFields: ["coord_ref_sys_code"],
    },
    // annotations: {
      // merge(existing, incoming, { mergeObjects }) {
      //   console.log({
      //     existing,
      //     incoming,
      //     mergeObjects
      //   })
      //   return [...existing, ...incoming];
      // }
    // }
  },
  // dataIdFromObject: object => {
  //   switch (object.__typename) {
  //     case 'processings_projects': return `processings_projects:${(object as any).projectId}_${(object as any).processingId}`; // use the `key` field as the identifier
  //     case 'spatial_ref_sys': return `spatial_ref_sys:${(object as any).srid}`;
  //     case 'epsg_epsg_coordinatereferencesystem': return `epsg_epsg_coordinatereferencesystem:${(object as any).coord_ref_sys_code}`;
  //     default: return defaultDataIdFromObject(object); // fall back to default handling
  //   }
  // }
 });

function createClient(setLastUpdateFn, firebaseAuth: Auth) {
  return new ApolloClient({
    cache: createCache(),
    link: createLink(setLastUpdateFn, firebaseAuth),
    connectToDevTools: true,
  });
}

function renderErrors(errors, customDict) {
  return errors.map(error => {
    let { message, field } = error;
    const {code} = error;

    if (customDict[message]) {
      message = customDict[message];
    } else {
      switch (message) {
        case 'email or password is invalid':
          field = 'email';
          message = 'Email ou senha inválidos.';
          break;
        case 'Network error: Failed to fetch':
          message = 'Falha na conexão. Tente novamente mais tarde.';
          break;
        default:
          message = 'Erro desconhecido. Tente novamente mais tarde.';
          break;
      }
    }

    return {
      code,
      field,
      ...(_.isString(message) ? { message } : message),
    };
  });
}

// // TODO: add support to payload errors
function mutationErrors(name, result, customDict = {}) {
  if (result.called && !result.loading) {
    const { error, data } = result;
    // Network error
    if (error) {
      return renderErrors([error], customDict);
    }

    if (!_.get(data, [name, 'successful'])) {
      return renderErrors(_.get(data, [name, 'messages'], []), customDict);
    }
  }

  return [];
}

type ClientType = ApolloClient<NormalizedCacheObject>;
// let client: ClientType;

export const GraphqlProvider = ({ children, firebaseApp }) => {
  const auth = getAuth(firebaseApp);
  const [lastUpdate, setLastUpdate] = useState(moment().format("YYYY-MM-DDTHH:mm:ss.SSS"));
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useLayoutEffect(() => {
    const haveClient = createClient(setLastUpdate, auth);
    if (haveClient) {
      setClient(() => haveClient);
    }
  }, [auth]);

  // Remove all data from the store. Unlike resetStore, clearStore will not refetch any active queries.
  const clearStore: ClientType['clearStore'] = client?.clearStore.bind(client);
  // Allows callbacks to be registered that are executed when the store is cleared. onClearStore returns an unsubscribe function that can be used to remove registered callbacks.
  const onClearStore: ClientType['onClearStore'] = client?.onClearStore.bind(client);
  // Resets your entire store by clearing out your cache and then re-executing all of your active queries.
  // This makes it so that you may guarantee that there is no data left in your store from a time before you called this method.
  const resetStore: ClientType['resetStore'] = client?.resetStore.bind(client);
  // Allows callbacks to be registered that are executed when the store is reset. onResetStore returns an unsubscribe function that can be used to remove registered callbacks.
  const onResetStore: ClientType['onResetStore'] = client?.onResetStore.bind(client);
  // const reFetchObservableQueries: ClientType['reFetchObservableQueries'] = clientRef.current.reFetchObservableQueries.bind(clientRef.current);

  return (
    client ?
      <ApolloProvider client={client}>
        {children({ resetStore, onResetStore, clearStore, onClearStore, wsLink: wsHasuraLink, lastUpdate, firebaseApp })}
      </ApolloProvider>
    : null
  );
};

export default GraphqlProvider;
